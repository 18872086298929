var exports = {};
var _a, _b;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isUnframed = exports.isClient = exports.isServer = void 0;
exports.isServer = typeof window === "undefined";
exports.isClient = !exports.isServer;
// eslint-disable-next-line ssr-friendly/no-dom-globals-in-module-scope
exports.isUnframed = exports.isClient && ((_b = (_a = window.navigator) === null || _a === void 0 ? void 0 : _a.userAgent) === null || _b === void 0 ? void 0 : _b.indexOf("Unframed")) > 0;
export default exports;
export const __esModule = exports.__esModule,
  isUnframed = exports.isUnframed,
  isClient = exports.isClient,
  isServer = exports.isServer;